<template>
  <v-container 
    tag="section"
    fluid
    >
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Grupos de Arquivos
        </div>
      </template>

      <v-btn
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float:left"
        relative
        text
        medium
        @click="$router.push('add');"
      >
        <v-icon left size="30px">
          mdi-plus-circle
        </v-icon>
        NOVO GRUPO
      </v-btn>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search bs-grupos-arquivos"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px;"
      />

      <v-divider class="mt-10" />

      <v-data-table
        class="grupos-arquivos-table"
        :headers="headers"
        :items="items"
        :search.sync="search"
        no-data-text="Nenhum grupo de arquivos disponível"
        no-results-text="Nenhum registro correspondente encontrado"
        :headerProps="{ sortByText: 'Ordenar Por' }"
        :footer-props="{ itemsPerPageText: 'Itens por Página' }"
        mobile-breakpoint="800"
      >
        <template v-slot:[`item.nome`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div 
                class="text-truncate-column" 
                v-bind="attrs" v-on="on"
              >
                {{ item.nome }}
              </div>
            </template>
            <span>{{ item.nome }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <span 
            class="font-weight-medium"
            :style="{color: item.ativo ? 'green' : 'red'}"
          >
            <v-icon 
              x-small class="pb-1"
              v-text="item.ativo ? 'mdi-thumb-up-outline' : 'mdi-thumb-down-outline'"
              :color="item.ativo ? 'green' : 'red'"
            />
            {{ item.ativo ? 'Sim' : 'Não' }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :loading="changingStatus[item.id] != undefined"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="toggleGrupoStatus(item.id)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  small
                  v-bind="attrs" 
                  v-on="on"
                >
                  {{ item.ativo ? 'mdi-thumb-down-outline' : 'mdi-thumb-up-outline' }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Desativar Grupo de Arquivo" : "Ativar Grupo de Arquivo" }}</span>
            </v-tooltip>
          </v-btn>
          <v-btn
            :class="changingStatus[item.id] != undefined ? 'cursor-block' : ''"
            :disabled="changingStatus[item.id] != undefined"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.push(`edit/${item.id}`)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openDialogDetalhes(item)">
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small> mdi-file-find-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Detalhes
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
          
      </v-data-table>
    </base-material-card>

    <general-dialog-detalhes
      ref="dialogDetalhes"
      title="Detalhes do Grupo de Arquivos"
      :showDialog="dialogDetalhes"
      @changeValue="dialogDetalhes = $event"
    >
      <template slot="body">
        <v-data-table
          :headers="dialogDetalhesHeader"
          :items="dialogDetalhesData"
          hide-default-footer
          class="elevation-1 style-table-dialog-detalhes"
          disable-pagination
          mobile-breakpoint="10"
        />
      </template>
    </general-dialog-detalhes>
  </v-container>
</template>

<script>
import GruposArquivosService from '@/services/GruposArquivosService.js';  
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';

export default {
  name: 'GruposArquivosDataTable',

  mixins: [powerupsActionsMixins],

  components: {
    GeneralStatus: () => import("@/components/general/GeneralStatus.vue"),
    GeneralDialogDetalhes: () => import('@/components/general/GeneralDialogDetalhes')
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    powerupService: GruposArquivosService,
    powerupEntityName: "Grupo de Arquivos",
    search: "",
    headers: [
      {
        text: "#",
        value: "id"
      },
      {
        text: "Nome",
        value: "nome"
      },
      {
        text: "Nº de Arquivos",
        value: "num_arquivos"
      },
      {
        text: "Ativo",
        value: "ativo"
      },
      {
        sortable: false,
        text: "Ações",
        value: "actions",
        class: "pl-4"
      }
    ],
    changingStatus: {},
    dialogDetalhesHeader: [
      { text: 'Resumo', value: 'parametro', sortable: false },
      { text: 'Valor', value: 'valor', sortable: false }
    ],
  }),

  methods: {
    toggleGrupoStatus(id) {
      this.$set(this.changingStatus, id, { id }); 

      GruposArquivosService.toggleStatus(id)
        .then(() => {
          this.$toast.success("Status do Grupo de Arquivos atualizado.", "", { position: "topRight", timeout: 1500 });
          this.$emit("rechargeTable");
        }).catch(err => {
          console.error(err);
          this.$toast.error("Erro ao atualizar status do Grupo de Arquivos.", "", { position: "topRight"});
        })
        .finally(() => {
          this.$delete(this.changingStatus, id);
        });
    },
    openDialogDetalhes(item) { 
      this.dialogDetalhesData = [
        {
          parametro: 'Nome do Grupo:',
          valor: item.nome,
        },
        {
          parametro: 'Criado por:',
          valor: item.criado_por
        },
        {
          parametro: 'Status:',
          valor: item.ativo ? "Ativo" : "Desativado"
        },
        {
          parametro: 'Número de Arquivos:',
          valor: item.num_arquivos,
        },
      ];

      this.dialogDetalhes = true;
    },
  }

}
</script>

<style>
@media screen and (min-width: 800px) {
  .grupos-arquivos-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .grupos-arquivos-table table tr td:nth-child(7) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-grupos-arquivos {
    width: 169.27px !important;
    clear: both !important;
  }
}
</style>