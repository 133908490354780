var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-file-tree","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Grupos de Arquivos ")])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add');}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" NOVO GRUPO ")],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-grupos-arquivos",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"grupos-arquivos-table",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":"Nenhum grupo de arquivos disponível","no-results-text":"Nenhum registro correspondente encontrado","headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{ itemsPerPageText: 'Itens por Página' },"mobile-breakpoint":"800"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.nome)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nome))])])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium",style:({color: item.ativo ? 'green' : 'red'})},[_c('v-icon',{staticClass:"pb-1",attrs:{"x-small":"","color":item.ativo ? 'green' : 'red'},domProps:{"textContent":_vm._s(item.ativo ? 'mdi-thumb-up-outline' : 'mdi-thumb-down-outline')}}),_vm._v(" "+_vm._s(item.ativo ? 'Sim' : 'Não')+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"loading":_vm.changingStatus[item.id] != undefined,"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.toggleGrupoStatus(item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.ativo ? 'mdi-thumb-down-outline' : 'mdi-thumb-up-outline')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.ativo ? "Desativar Grupo de Arquivo" : "Ativar Grupo de Arquivo"))])])],1),_c('v-btn',{staticClass:"px-1 ml-n1",class:_vm.changingStatus[item.id] != undefined ? 'cursor-block' : '',attrs:{"disabled":_vm.changingStatus[item.id] != undefined,"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDetalhes(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-find-outline ")])],1),_c('v-list-item-title',[_vm._v(" Detalhes ")])],1)],1)],1)]}}],null,true)})],1),_c('general-dialog-detalhes',{ref:"dialogDetalhes",attrs:{"title":"Detalhes do Grupo de Arquivos","showDialog":_vm.dialogDetalhes},on:{"changeValue":function($event){_vm.dialogDetalhes = $event}}},[_c('template',{slot:"body"},[_c('v-data-table',{staticClass:"elevation-1 style-table-dialog-detalhes",attrs:{"headers":_vm.dialogDetalhesHeader,"items":_vm.dialogDetalhesData,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"10"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }